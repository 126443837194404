















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  directives: { mask },
  components: {
    ValidationObserver,
    PasswordConfirmField
  }
})
export default class ConvertToModelModal extends Mixins(FlashMessageMixin) {
  @Prop({ default: false }) visible!: boolean
  @Prop({ type: Number }) eventVacancies!: number

  form: Record<string, any>= {
    vacancies: 0,
    confirm: '',
    errors: {
      vacancies: '',
      confirm: ''
    }
  }

  created () {
    this.form.vacancies = this.eventVacancies ?? 1
  }

  closeModal () {
    this.clearForm()
    this.$emit('close')
  }

  async submit () {
    axios.patch(`/event/${this.$route.params.id}/vacancies`, this.form)
      .then(() => {
        this.$emit('submitted', this.form.vacancies)
        this.clearForm()
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  $refs!: {
    observer: any;
  }

  clearForm () {
    this.form = {
      vacancies: this.eventVacancies,
      confirm: '',
      errors: {
        confirm: ''
      }
    }

    this.$refs.observer.reset()
  }
}

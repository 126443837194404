














import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class RegionalsSelect extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String }) invalidMessage!: string
  regionals: Array<Regional> = []

  created () {
    axios.get('services/regionals')
      .then((response) => {
        this.regionals = response.data.data

        Vue.nextTick(() => {
          if (!this.inputField) {
            this.inputField = this.regionals[0]?.services[0]?.id
          }
        })
      })
  }
}

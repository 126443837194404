







































import { Component, Mixins, Prop, ModelSync, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { mask } from 'vue-the-mask'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

@Component({
  directives: { mask },
  components: {
    ValidationProvider
  }
})
export default class TimeInput extends Mixins(ValidatorConfigMixin) {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: Boolean }) required!: boolean
  @Prop({ type: String }) hourRules!: string
  @Prop({ type: String }) minutesRules!: string

  time = {
    hour: '00',
    minutes: '00'
  }

  created () {
    const [hour, minutes] = this.inputField.split(':')
    this.time = {
      hour,
      minutes
    }
  }

  @Watch('time', { deep: true })
  onTimeChange () {
    const { leadingZero, time } = this
    this.inputField = `${leadingZero(time.hour)}:${leadingZero(time.minutes)}:00`
  }

  get getHourRules () {
    return this.calculateRuleSet(this.hourRules)
  }

  get getMinutesRules () {
    return this.calculateRuleSet(this.minutesRules)
  }

  calculateRuleSet (rules: string) {
    let ruleSet = rules ?? ''

    if (this.required) {
      ruleSet += rules ? '|required' : 'required'
    }

    return ruleSet
  }

  private leadingZero (num: string) {
    return num.length === 2 ? num : '0' + num
  }
}

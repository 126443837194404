









































































































































































































































































import { Component, Mixins, Prop, Watch, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import MunicipalitiesComboBox from '@/partials/forms/Services/components/MunicipalitiesComboBox.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RegionalsSelect from '@/partials/forms/components/RegionalsSelect.vue'
import UFDropdown from '@/partials/forms/components/UFDropdown.vue'
import DateTimeInput from '@/partials/forms/components/DateTime/DateTime.vue'
import ProfileUploader from '@/partials/components/ProfileUploader.vue'
import UpdateVacanciesModal from '@/partials/modals/Events/UpdateEventVacancies.vue'

@Component({
  directives: { mask },
  components: {
    ProfileUploader,
    PasswordConfirmField,
    RegionalsSelect,
    UFDropdown,
    DateTimeInput,
    MunicipalitiesComboBox,
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    UpdateVacanciesModal
  }
})
export default class EventsForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop() event!: Record<string, any>
  done = true

  form: Record<string, any> = {
    name: '',
    address: '',
    cep: '',
    ufId: '',
    municipalityId: '',
    regionalId: '',
    status: 'active',
    acceptingSubscribers: '',
    acceptingStudies: '',
    showLoginForm: '',
    availableOn: moment().format('YYYY-MM-DD HH:mm:ss'),
    availableUp: moment().format('YYYY-MM-DD HH:mm:ss'),
    picture: '',
    phone: '',
    email: '',
    site: '',
    vacancies: 500,
    confirm: '',
    errors: {
      name: '',
      address: '',
      cep: '',
      ufId: '',
      municipalityId: '',
      regionalId: '',
      status: '',
      acceptingSubscribers: '',
      acceptingStudies: '',
      showLoginForm: '',
      availableOn: '',
      availableUp: '',
      phone: '',
      email: '',
      site: '',
      vacancies: '',
      confirm: ''
    }
  }

  profilePictureURL = ''
  pictureId = ''
  municipalities = []
  statuses = [
    {
      name: 'active',
      prettyName: 'Ativo'
    },
    {
      name: 'completed',
      prettyName: 'Realizado'
    },
    {
      name: 'canceled',
      prettyName: 'Cancelado'
    },
    {
      name: 'demo',
      prettyName: 'Demonstração'
    }
  ]

  statusesAcceptingSubscribers = [
    {
      name: true,
      prettyName: 'Sim'
    },
    {
      name: false,
      prettyName: 'Não'
    }
  ]

  statusesAcceptingStudies = [
    {
      name: true,
      prettyName: 'Sim'
    },
    {
      name: false,
      prettyName: 'Não'
    }
  ]

  statusesShowLoginForm = [
    {
      name: true,
      prettyName: 'Exibir'
    },
    {
      name: false,
      prettyName: 'Esconder'
    }
  ]

  vacanciesModalIsOpened = false

  isCreateFinished = false
  created () {
    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        name: 'Evento Teste ABC',
        address: 'Rua Tal, Avenida Tal',
        cep: '875648-853',
        ufId: '53',
        municipalityId: '5300108',
        regionalId: '',
        status: 'active',
        acceptingSubscribers: true,
        acceptingStudies: true,
        showLoginForm: false,
        availableOn: moment().format('YYYY-MM-DD HH:mm:ss'),
        availableUp: moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        phone: '(61) 3456-7890',
        email: 'evento@indicativa.com.br',
        site: 'https://sbq.indicativa.com.br',
        vacancies: 500,
        confirm: ''
      })

      Vue.nextTick(async () => {
        this.isCreateFinished = true
        await this.fetchMunicipalities(this.form.ufId)
        this.form.municipalityId = '5300108'
      })
    }

    if (this.type === 'edit') {
      Object.keys(this.event).forEach((key: string) => {
        this.form[key] = key in this.form ? this.event[key] : this.form[key]

        if (this.form.picture) {
          this.profilePictureURL = this.form.picture.publicPath
          this.pictureId = this.form.picture.id
        }
      })
      Vue.nextTick(async () => {
        this.isCreateFinished = true
        await this.fetchMunicipalities(this.form.ufId)
        this.form.municipalityId = this.event.municipalityId
      })
    }

    this.isCreateFinished = true
  }

  windowWidth = window.innerWidth
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    this.windowWidth = window.innerWidth
  }

  @Watch('form.ufId')
  fetchMunicipalities (newValue: string) {
    if (this.isCreateFinished) {
      return axios.get(`geo/br/municipalities/${newValue}`)
        .then((response) => {
          this.municipalities = response.data.data
          this.form.municipalityId = ''
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit' ? `/event/${this.event.id}` : '/events'

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.name)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (serviceName: string) {
    const successVerb = this.type === 'edit' ? 'editado' : 'cadastrado'

    const flashMessage = {
      message: `O evento ${serviceName} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    if (this.type === 'create') {
      this.$router.push({ name: 'Events' }, () => this.setFlashMessage(flashMessage))
    } else {
      this.$router.push({ name: 'EventShow', params: { id: this.$route.params.id } }, () => this.setFlashMessage(flashMessage))
    }
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      address: '',
      cep: '',
      ufId: '',
      municipalityId: '',
      regionalId: '',
      status: '',
      acceptingStudies: '',
      showLoginForm: '',
      acceptingSubscribers: '',
      availableOn: '',
      availableUp: '',
      phone: '',
      site: '',
      vacancies: '',
      confirm: ''
    }
  }
}

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

type ComboOption = {
  value: string;
  name: string;
  label: string;
}
@Component
export default class MunicipalitiesComboBox extends Vue {
  @Prop() formMunicipalities!: Array<Municipality>
  @Prop() value!: string
  @Prop() disabled!: boolean
  @Prop() invalidMessage!: string
  comboOptions: Array<ComboOption> = []
  municipalities: Array<Municipality> = []
  pristine = true

  @Watch('formMunicipalities', { immediate: true })
  onMunicipalitiesUpdate (newValue: Array<Municipality>) {
    this.comboOptions = newValue.map((municipality: Municipality) => {
      const option = {
        value: municipality.id,
        name: municipality.name,
        label: municipality.name
      }

      return option
    })
  }

  filterComboOptions ($e: Event) {
    const target = $e.target as HTMLInputElement
    const value = target.value
    this.pristine = true
    const filterdeMunicipalities = this.formMunicipalities.filter((municipality: Municipality) => {
      return municipality.name.toLowerCase().includes(value)
    })
    this.onMunicipalitiesUpdate(filterdeMunicipalities)
  }

  changed (id: string) {
    this.$emit('input', id)
  }
}

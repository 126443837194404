

















import { Component, Vue, ModelSync, Watch, Prop } from 'vue-property-decorator'
import moment from 'moment'

import DateInput from './Date.vue'
import TimeInput from './Time.vue'

@Component({
  components: {
    DateInput,
    TimeInput
  }
})
export default class DateTimeInput extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String }) label!: string
  @Prop({ type: String }) minDateTime!: string
  @Prop({ type: String }) maxDateTime!: string

  date = ''
  time = ''

  created () {
    const [date, time] = this.inputField.split(' ')
    this.date = date
    this.time = time
  }

  get hourRules () {
    let ruleSet = ''
    if (!this.minDateTime && !this.maxDateTime) return ruleSet
    const momentCurrent = moment(this.inputField)

    if (this.minDateTime) {
      const momentMin = moment(this.minDateTime)
      ruleSet = `min_value:${momentMin.isSame(momentCurrent, 'day') ? momentMin.hours() : 0}`
    }

    return ruleSet
  }

  get minutesRules () {
    let ruleSet = ''
    if (!this.minDateTime && !this.maxDateTime) return ruleSet
    const momentCurrent = moment(this.inputField)

    if (this.minDateTime) {
      const momentMin = moment(this.minDateTime)
      ruleSet = `min_value:${momentMin.isSame(momentCurrent, 'day') && momentMin.isSame(momentCurrent, 'hour') ? momentMin.minute() : 0}`
    }

    return ruleSet
  }

  @Watch('date')
  @Watch('time')
  onTimeDateChange () {
    const date = this.date.split(' ')[0]
    this.inputField = `${date} ${this.time}`
  }
}

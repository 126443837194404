var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'edit')?_c('div',{staticClass:"flex justify-center items-center"},[_c('ProfileUploader',{attrs:{"img-url":_vm.profilePictureURL,"owner-id":_vm.$route.params.id,"picture-id":_vm.pictureId,"type":"event"},on:{"image-loaded":function($event){_vm.form.picture = $event},"deleted":function($event){_vm.profilePictureURL = ''; _vm.pictureId = ''}}})],1):_vm._e(),_c('ValidationObserver',{staticClass:"py-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',[_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.type === 'edit')?_c('div',{staticClass:"flex flex-col mt-4"},[_c('strong',[_vm._v(" Total de Vagas: "+_vm._s(_vm.form.vacancies)+" ")]),_c('cv-button',{staticClass:"mt-2",attrs:{"kind":"secondary","size":"small"},on:{"click":function($event){_vm.vacanciesModalIsOpened = true}}},[_vm._v(" Modificar Vagas do Evento ")])],1):_vm._e()]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-5",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome do Evento","invalid-message":_vm.form.errors.name || errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-5",attrs:{"name":"address","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Endereço","invalid-message":_vm.form.errors.address || errors[0]},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-2",attrs:{"name":"CEP","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"CEP","invalid-message":_vm.form.errors.cep || errors[0]},model:{value:(_vm.form.cep),callback:function ($$v) {_vm.$set(_vm.form, "cep", $$v)},expression:"form.cep"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-1",attrs:{"tag":"div","name":"uf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UFDropdown',{attrs:{"invalid-message":_vm.form.errors.uf_id || errors[0]},model:{value:(_vm.form.ufId),callback:function ($$v) {_vm.$set(_vm.form, "ufId", $$v)},expression:"form.ufId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"municipality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MunicipalitiesComboBox',{attrs:{"form-municipalities":_vm.municipalities,"disabled":!_vm.form.ufId,"invalid-message":_vm.form.errors['municipalityId'] || errors[0]},model:{value:(_vm.form.municipalityId),callback:function ($$v) {_vm.$set(_vm.form, "municipalityId", $$v)},expression:"form.municipalityId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"regional"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('RegionalsSelect',{attrs:{"invalid-message":_vm.form.errors.regionalId || errors[0]},model:{value:(_vm.form.regionalId),callback:function ($$v) {_vm.$set(_vm.form, "regionalId", $$v)},expression:"form.regionalId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"bx--label"},[_vm._v(" Status ")]),_c('cv-radio-group',{attrs:{"vertical":_vm.windowWidth < 768}},[(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione 1 ")]):_vm._e(),_vm._l((_vm.statuses),function(status){return _c('cv-radio-button',{key:status.name,attrs:{"value":status.name,"label":status.prettyName},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})})],2)]}}],null,true)}),_c('div',{staticClass:"lg:col-span-6"},[_c('DateTimeInput',{attrs:{"label":"Data de Início"},model:{value:(_vm.form.availableOn),callback:function ($$v) {_vm.$set(_vm.form, "availableOn", $$v)},expression:"form.availableOn"}})],1),_c('div',{staticClass:"lg:col-span-6"},[_c('DateTimeInput',{attrs:{"label":"Data de Término","min-date-time":_vm.form.availableOn},model:{value:(_vm.form.availableUp),callback:function ($$v) {_vm.$set(_vm.form, "availableUp", $$v)},expression:"form.availableUp"}})],1),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors['phone'] || errors[0],"label":"Telefone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-4",attrs:{"tag":"div","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"E-mail","invalid-message":_vm.form.errors.email || errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('div',{staticClass:"lg:col-span-3"},[_c('cv-text-input',{attrs:{"label":"Site"},model:{value:(_vm.form.site),callback:function ($$v) {_vm.$set(_vm.form, "site", $$v)},expression:"form.site"}})],1),(_vm.type === 'create')?_c('ValidationProvider',{staticClass:"lg:col-span-2",attrs:{"tag":"div","name":"vacancies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-number-input',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"label":"Vagas","invalid-message":_vm.form.errors.vacancies || errors[0],"type":"number","min":"0","value":0},model:{value:(_vm.form.vacancies),callback:function ($$v) {_vm.$set(_vm.form, "vacancies", $$v)},expression:"form.vacancies"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"acceptingSubscribers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"bx--label"},[_vm._v(" Inscrições abertas? ")]),_c('cv-radio-group',{attrs:{"vertical":_vm.windowWidth < 768}},[(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione 1 ")]):_vm._e(),_vm._l((_vm.statusesAcceptingSubscribers),function(acceptingSubscribers){return _c('cv-radio-button',{key:acceptingSubscribers.name,attrs:{"value":acceptingSubscribers.name,"label":acceptingSubscribers.prettyName},model:{value:(_vm.form.acceptingSubscribers),callback:function ($$v) {_vm.$set(_vm.form, "acceptingSubscribers", $$v)},expression:"form.acceptingSubscribers"}})})],2)]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"acceptingStudies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"bx--label"},[_vm._v(" Aceitando envio de Trabalhos Científicos? ")]),_c('cv-radio-group',{attrs:{"vertical":_vm.windowWidth < 768}},[(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione 1 ")]):_vm._e(),_vm._l((_vm.statusesAcceptingStudies),function(acceptingStudies){return _c('cv-radio-button',{key:acceptingStudies.name,attrs:{"value":acceptingStudies.name,"label":acceptingStudies.prettyName},model:{value:(_vm.form.acceptingStudies),callback:function ($$v) {_vm.$set(_vm.form, "acceptingStudies", $$v)},expression:"form.acceptingStudies"}})})],2)]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"showLoginForm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"bx--label"},[_vm._v(" Exibir campos de Login e Crachá QR Code no Aplicativo? ")]),_c('cv-radio-group',{attrs:{"vertical":_vm.windowWidth < 768}},[(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione 1 ")]):_vm._e(),_vm._l((_vm.statusesShowLoginForm),function(showLoginForm){return _c('cv-radio-button',{key:showLoginForm.name,attrs:{"value":showLoginForm.name,"label":showLoginForm.prettyName},model:{value:(_vm.form.showLoginForm),callback:function ($$v) {_vm.$set(_vm.form, "showLoginForm", $$v)},expression:"form.showLoginForm"}})})],2)]}}],null,true)})],1),_c('div',{staticClass:"w-full lg:w-1/3"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])}),_c('UpdateVacanciesModal',{attrs:{"visible":_vm.vacanciesModalIsOpened,"event-vacancies":_vm.form.vacancies},on:{"close":function($event){_vm.vacanciesModalIsOpened = false},"submitted":function($event){_vm.vacanciesModalIsOpened = false; _vm.form.vacancies = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
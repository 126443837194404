
















import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class UFDropdown extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String }) invalidMessage!: string
  ufs = []

  created () {
    axios.get('geo/br/states')
      .then((response) => {
        this.ufs = response.data.data
      })
  }
}
